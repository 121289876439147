/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {






        $('.mobile-button').on('click', function(){

          $('.nav-primary').slideToggle();
        });

        jQuery(document).ready(function() {
          var offset = 250;
          var duration = 300;
          jQuery(window).scroll(function() {
            if (jQuery(this).scrollTop() > offset) {
              jQuery('.back-to-top').fadeIn(duration);
            } else {
              jQuery('.back-to-top').fadeOut(duration);
            }
          });
          jQuery('.back-to-top').click(function(event) {
            event.preventDefault();
            jQuery('html, body').animate({scrollTop: 0}, duration);
            return false;
          });
        });



        // with jQuery
        $('.grid').isotope({
          itemSelector: '.grid-item',
          percentPosition: true,

          masonry: {
            isFitWidth: false,
            gutter: 10,
            columnWidth: '.grid-sizer'
          }
        });

        if($(window).width() >= 1200){

          if($(document).scrollTop() > 200){
            $('header.sticky-header').addClass('open');
            $('header.sticky-header').slideDown();
          }
        } else {

        }


        $(window).scroll(function () {
          if($(window).width() >= 1200){
            if ( $(this).scrollTop() > 200 && !$('header').hasClass('open') ) {
              $('header.sticky-header').addClass('open');
              $('header.sticky-header').slideDown();
            } else if ( $(this).scrollTop() <= 200 ) {
              $('header.sticky-header').removeClass('open');
              $('header.sticky-header').slideUp();
            }
          }
        });

        $(window).resize(function() {
          if($(window).width() < 1200){
            $('p').each(function() {
              var $this = $(this);
              if($this.html().replace(/\s|&nbsp;/g, '').length === 0){
                $(this).hide();
              }

            });

            $('header.sticky-header').removeClass('open');
            $('header.sticky-header').slideUp();
          } else {
            $('p').each(function() {
              var $this = $(this);
              if($this.html().replace(/\s|&nbsp;/g, '').length === 0){
                $(this).show();
              }

            });
          }
        });


        //footer fixer
        /*
        jQuery(window).resize(function () {
        $('main').css('padding-bottom', $('footer').innerHeight());
      });
      $('main').css('padding-bottom', $('footer').innerHeight());
      */
    },
    finalize: function() {



    }
  },
  // Home page
  'home': {
    init: function() {
      // JavaScript to be fired on the home page


      $('.slick-slider').slick({
        dots: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        nextArrow: '<span class="fa fa-angle-right slick-next" aria-hidden="true"></span>',
        prevArrow: '<span class="fa fa-angle-left slick-prev" aria-hidden="true"></span>',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false
            }
          }
        ]
      });

    },
    finalize: function() {
      // JavaScript to be fired on the home page, after the init JS
    }
  },
  // About us page, note the change from about-us to about_us.
  'about_us': {
    init: function() {
      // JavaScript to be fired on the about us page
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var fire;
    var namespace = Sage;
    funcname = (funcname === undefined) ? 'init' : funcname;
    fire = func !== '';
    fire = fire && namespace[func];
    fire = fire && typeof namespace[func][funcname] === 'function';

    if (fire) {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    // Fire common init JS
    UTIL.fire('common');

    // Fire page-specific init JS, and then finalize JS
    $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
      UTIL.fire(classnm);
      UTIL.fire(classnm, 'finalize');
    });

    // Fire common finalize JS
    UTIL.fire('common', 'finalize');
  }
};

// Load Events
$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.








function infiniteGal(photoSk, perPage, currentPage, postID, home, seed){


      jQuery('.galerijos-pabaiga .spinner').fadeIn();
      jQuery.ajax({
        url : 'http://'+window.location.host+'/stebukline/wp-admin/admin-ajax.php',
        type : 'post',
        data : {
          action : 'gallery_pagination',
          photoSk: photoSk,
          perPage: perPage,
          currentPage: currentPage,
          postID: postID,
          home: home,
          seed: seed
        },
        success : function( response ) {

          var parsedResponse = JSON.parse(response);
          var imgs = parsedResponse[1];
          var info = parsedResponse[0];
          for (var i = 0; i < imgs.length; i++) {
            var items;
            if(info.home === 'false'){
              items = jQuery("<div class='grid-item'><a href='"+ imgs[i].imgB +"'  rel='lightbox'><img src='"+ imgs[i].imgS +"' alt='" + imgs[i].alt +"' title='" + imgs[i].title +"' /><div class='hoveris'></div></a></div>");
            } else {
              items = jQuery("<div class='grid-item'><a href=/?p="+ imgs[i].postID +" ><img src='"+ imgs[i].imgS +"' alt='" + imgs[i].alt +"' title='" + imgs[i].title +"' /></a></div>");
            }


            jQuery('.grid').append( items ).isotope( 'appended', items);
          }

          jQuery('.grid').imagesLoaded(function(){
                jQuery('.grid').isotope( 'layout');
          });



          jQuery('.galerijos-pabaiga').attr('data-page', info.page);
          jQuery('.galerijos-pabaiga .spinner').fadeOut();
        },
        error: function (request, status, error) {
           alert(console.log('AJAX error!'));
           jQuery('.galerijos-pabaiga .spinner').fadeOut();
       }
      });


}

jQuery(window).resize(function () {
  jQuery(window).bind("load resize", function() {
      var container_width = jQuery('.fb-page-footer').width();

      jQuery('#WrapperID').html('<div class="fb-page" ' +
          'data-href="' + jQuery("#WrapperID").data("href") + '"' +
          ' data-width="' + container_width + '" data-height="220" data-tabs="timeline" data-show-faces="true" ' +
          'data-stream="true" data-header="true"></div>');
      FB.XFBML.parse();
  });
});
